$cell-padding: 1rem 1.2rem 1rem 1.2rem;
$cell-line-height: 1.625;
$border-color: #ddd;
$gray100: #efefef;
$gray500: #7e7e7e;

.layoutstyle .datatable-scroll {
  width: 100% !important;
}

.layoutstyle.nofooter {
  .datatable-body {
    border-bottom: 1px solid #efefef;
  }
}

[class^='datatable-icon-']:before,
[class*=' datatable-icon-']:before {
  font-family: 'Material Design Icons' !important;
}
.datatable-icon-down:before {
  content: '\F140' !important;
}
.datatable-icon-up:before {
  content: '\F143' !important;
}

.ngx-datatable.has-dropdown {
  overflow: visible !important;
  .datatable-body {
    overflow-x: visible !important;
    -webkit-overflow-scrolling: touch;
  }
}

.datatable-checkbox {
  padding: 0 0 !important;
}
.datatable-checkbox input[type='checkbox'] {
  margin: 0 0 !important;
}

.datatable-group-header {
  .datatable-checkbox-wrapper {
    padding: 7px 0 0 13px;
    float: left;
  }
  .datatable-toggle-wrapper {
    margin-left: 20px;
    float: left;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
  }
}

.hide-header .datatable-header {
  display: none !important;
}

.ngx-datatable.has-media .datatable-body .datatable-row-detail {
  overflow: hidden;
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: $gray100 !important;
  .border-left {
    border-left: 4px solid $gray500 !important;
    padding: 12px 15px;
    .mdi::before {
      line-height: inherit;
    }
  }
}

.ngx-datatable.layoutstyle.single-selection .datatable-body .datatable-body-row.active .datatable-body-cell,
.ngx-datatable.layoutstyle.single-selection .datatable-body .datatable-body-row.active .datatable-body-cell .btn {
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: $gray100;
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: $gray100;
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:focus,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:focus .datatable-row-group,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:focus,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:focus .datatable-row-group,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:focus,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: $gray100;
}

.ngx-datatable.layoutstyle .empty-row {
  padding: 1.25em;
}

.datatable-pager {
  margin: 0 10px;
}

.datatable-pager li {
  vertical-align: middle;
}

.datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}

.datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}

.datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px !important;
  margin: 6px 3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}

.datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}

.datatable-pager .datatable-icon-left,
.datatable-pager .datatable-icon-skip,
.datatable-pager .datatable-icon-right,
.datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px 0;
}

.datatable-checkbox input[type='checkbox'] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}

.datatable-checkbox input[type='checkbox']:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: '';
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}

.datatable-checkbox input[type='checkbox']:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}

.datatable-checkbox input[type='checkbox']:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: $white;
  cursor: pointer;
}

.datatable-body-row {
  border-bottom: 1px solid #dddddd;
}

.datatable-header-cell,
.datatable-body-cell {
  border-right: 1px solid #dddddd;
  &:last-of-type {
    border-style: none;
  }
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #eeeeee;
}

.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.layoutstyle.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #eeeeee;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #eeeeee;
}

.ngx-datatable.clickable .datatable-body .datatable-body-row > div {
  cursor: pointer;
}

.ngx-datatable.hide-pointer .datatable-body .datatable-body-row > div {
  cursor: auto;
}

.dropdown-menu.dropdown-menu-left {
  li {
    padding: 10px;
  }
}

.is-parent {
  background: #dddddd;
}
.has-parent .datatable-body-cell-label {
  padding-left: 20px;
}

.datatable-body-cell-label {
  word-break: break-word;
}

.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: grid;
}

table.layoutstyle {
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid $gray100;

  thead {
    tr {
      th {
        text-align: left;
        color: $gray750;
        padding: 1.25em;
      }
    }
  }
  td {
    &:not(.text-center, .text-right) {
      text-align: left;
    }
    padding: 1.25em;
    font-size: inherit;
    border-top: 1px solid $gray100;

    &.no-border-inside {
      td {
        border-top: 0px;
      }
    }
    &.input-padding {
      padding: 4px 15px;
    }
  }
  .document-padding {
    padding: 1.15em;
  }
  .button-top-padding {
    margin-top: 5px;
  }
}

body.gu-unselectable.ngx-datatable.cleanzone .datatable-body.gu-mirror {
  .datatable-body-cell {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.progress-linear {
  display: block;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;

  .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    -webkit-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    background-color: rgba($primaryColor, 0.2);

    .bar {
      transition: all 0.2s linear;
      -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);

      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      background-color: $primaryColor;

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
    }
  }
}

.ngx-datatable button {
  &.datatable-tree-button,
  &.app-tree-button {
    display: none;
  }
}

.p-card {
  margin-bottom: 24px;
}

.row-multiple-input-spacing {
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .p-datatable-tbody > tr {
    border-bottom: 1px solid #efefef;
    > td.has-icon {
      max-width: fit-content !important;
      float: left;
      border-bottom: none !important;

      &:last-of-type {
        &:after {
          clear: both;
          content: '';
          float: none;
        }
      }
    }

    td.empty {
      display: none !important;
    }
  }
}
