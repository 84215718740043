body .fc .fc-toolbar .fc-button {
  background-color: $gray300;
}

.fc-button-group {
  .fc-button {
    border-radius: 0px;
  }

  & > :first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  & > :last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.fc-button {
  height: 32px !important;
  line-height: 32px;
  border-radius: 6px;
}

.fc-event {
  font-size: 11px !important;
  border-radius: 6px;
}

timepicker {
  table {
    width: auto;
    td {
      padding: 0 !important;
      border: none !important;
      .btn {
        border: none !important;
      }
    }
  }
}
