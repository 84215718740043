.p-tabview {
  margin-top: 6px;
  .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
    span {
      padding: 0.571em 1em;
    }
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.p-tabview-nav-link {
  background-color: $primaryColor !important;
  color: $white !important;
  transition: all ease 0.3s;

  .p-highlight & {
    background-color: $white !important;
    color: $black !important;
    pointer-events: none;
  }

  &:hover {
    background-color: #c41039 !important;
    transition: all ease 0.3s;
    color: $white !important;
  }
}
