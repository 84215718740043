/**
 * Base
 */

.mat-select {
  font-family: $fontFamily !important;
}

/**
 * Init page before bootstrap
 */

.loader-text {
  position: relative;
  margin-top: 30px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  font-family: $fontFamily;
  text-transform: uppercase;
  font-weight: 700;
  color: $primaryColor;
  letter-spacing: 0.2em;
}

.page-head h2 {
  color: $primaryColor;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/**
 * General background image
 */
.background-image,
.login-container {
  //background-image: url('../assets/images/background.png');
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #222;
}

.toast-success {
  background-color: $gray500 !important;
}
.toast-error {
  background-color: $dangerColor !important;
}
.toast-info {
  background-color: $infoColor !important;
}
.toast-warning {
  background-color: $warningColor !important;
}

.table td {
  font-size: $fontSize;
}

.table.no-border td {
  border-width: 0 !important;
}

.table-header {
  color: $black;
}
.table-desc {
  font-weight: bold;
  color: $gray500;
  width: 140px;
}

.table.table-info {
  > thead > tr > th {
    border-bottom: 1px solid #dddddd;
    padding: 4px 0;
  }
  td {
    padding: 4px 0;
  }
  border-bottom: 1px solid #dddddd;
}

.color-white {
  color: #ffffff !important;
}

.color-red {
  color: red;
}

.color-dark-gray {
  color: $gray500 !important;
}

.border-red {
  border: 2px solid red;
}

.cdk-overlay-container {
  z-index: 9999;
}

hr.divider-color-gray {
  width: 100%;
  border: none;
  border-top: 1px solid $gray100;
}

/*.sidebar {
  position: absolute;
  width: calc( 100% + 15px );
  height: calc(100vh - 215px);
  top: -130px;
  z-index: 1;
  margin: 0 0 0px -15px;
  background: gray;
}*/

.sidebar {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 1;
  margin: 0 0 0px -15px;
  background: #eee;
}

.sidebar-content {
  /*position: fixed;*/
  position: relative;
  z-index: 1;
  //width: calc(100% - -15px);
  /*overflow-x: hidden;
  height: calc(100vh - 250px);*/
}
/*
.sidebar-content::-webkit-scrollbar {
  width: 10px;
}
.sidebar-content::-webkit-scrollbar-thumb {
  background: rgba(254, 248, 240, 0.3);
}

.sidebar-content::-webkit-scrollbar-track {
  background: none;
}*/

@media (max-width: 991px) {
  .sidebar {
    display: none;
  }
  .sidebar-content {
    position: relative;
    height: auto;
  }
}

.modal {
  .modal-body {
    .modal-text {
      margin-bottom: 20px;
    }
  }
}

.media-modal {
  .modal-header {
    padding: 10px !important;
  }
  h4 {
    margin: 0;
  }
}

//.mat-horizontal-stepper-header, .mat-vertical-stepper-header{
//  pointer-events: none !important;
//}

.multi-line,
.multi-line > div {
  white-space: pre-line;
}
//
//.ql-mention-list-container {
//  width: auto !important;
//  z-index: 99999 !important;
//  background: #ffffff;
//  border: 1px solid #000000;
//  padding: 10px;
//  border-radius: 5px;
//
//  .ql-mention-list {
//    margin: 0;
//    padding: 0;
//    list-style-type: none;
//
//    .ql-mention-list-item {
//      cursor: pointer;
//      height: auto !important;
//      line-height: 1 !important;
//      padding: 8px 10px !important;
//    }
//  }
//
//
//
//}
//span.ql-mention-denotation-char {
//  display: none !important;
//}
//
//.ql-container, .ql-editor {
//  min-height: 150px;
//}
//
//.icon-picker button {
//  background-image: none;
//  border: none;
//}
//
//.cip-genpw-icon.cip-icon-key-big{
//  display: none !important;
//}

.CodeMirror {
  border: 1px solid #eee;
}

.CodeMirror-scroll {
  min-height: 400px;
}

.btn {
  .btn-text + .fa {
    position: relative;
    top: 1px;
    margin-left: 5px;
  }

  &.btn-outline-light {
    background-color: transparent;
    color: $white;
    border-color: $white;
    border-width: 1px;
  }
}

.form-control.plaintext {
  display: block;
  width: 100%;
  //padding-top: $input-padding-y;
  //padding-bottom: $input-padding-y;
  margin-bottom: 0;
  //line-height: $input-line-height;
  //color: $input-plaintext-color;
  background-color: transparent !important;
  border: solid transparent !important;
  border-width: 0 0 !important;
}

.input-group-addon.plaintext {
  //padding-top: $input-padding-y;
  //padding-bottom: $input-padding-y;
  //line-height: $input-line-height;
  //color: $input-plaintext-color;
  background-color: transparent !important;
  border: solid transparent !important;
  border-width: 0 0 !important;
}

.btn-group.btn-group-toggle {
  .btn {
    &.active {
      background: $primaryColor;
      color: $white;
    }

    &:active {
      background: darken($primaryColor, 8%);
      color: $white;
    }
  }
}

.show-badges {
  .mention {
    & > span {
      display: none;
    }

    &:after {
      content: attr(data-title);
      padding: 2px 7px;
      background: #666 !important;
      border-radius: 10px;
      color: #ffffff;
    }
  }
}

table.table-info td.table-desc {
  padding-right: 10px;
}

.toggle-margin {
  margin-top: 8px;
}

.p-tabview-top.no-content .p-tabview .p-tabview-panels {
  padding: 30px 0 0 0;
  height: auto !important;
}

.min-width-auto {
  min-width: auto !important;
}

// TODO: Redesign - verplaats .hidden classes naar ngx-ivengi/layout
$screen-sm: 576px !default;
$screen-md: 768px !default;
$screen-lg: 992px !default;
$screen-xl: 1200px !default;

.hidden-xs,
.hidden-sm,
.hidden-md,
.hidden-lg,
.hidden-xl {
  display: block;
}

@include media(extraSmall) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: $screen-lg) and (max-width: $screen-xl) {
  .hidden-lg {
    display: none !important;
  }
}
@include media(largeUp) {
  .hidden-xl {
    display: none !important;
  }
}

.hide-p-card {
  .p-card {
    background-color: transparent;
    box-shadow: none;

    .p-card-body {
      padding: 0;
    }
  }
}

.iv-l-header {
  z-index: 999;

  .iv-l-header-not-nav {
    max-width: 100% !important;
  }
}
.iv-l-sidebar .iv-l-vnavigation.iv-l-vnavigation-bottom {
  background-color: $primaryColor !important;
  & .menu-item a.nav-link {
    color: #fff !important;
  }
}

.iv-l-header .iv-l-header-inner .iv-l-header-logo {
  float: left;
  background-color: #f6f6f6;
  margin: 0 0 5px 0;
  transition: all ease 0.3s;

  img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
    padding: 10px 20px;
  }
}
@media (max-width: $screen-md) {
  .iv-l-header .iv-l-header-inner .iv-l-header-logo {
    width: 70px;
    img {
      padding: 0 !important;
    }
  }
}
.iv-l-page-head.page-head-scrolled {
  visibility: hidden;
}

.align-middle {
  vertical-align: middle;
}

.address-container {
  width: calc(100% - 20px);

  .address-col {
    margin-bottom: 5px;

    input {
      width: 100% !important;
    }
  }
}

.app-links {
  padding: 10px 0;
}

.p-button {
  opacity: 1;
  &.color-danger {
    color: red !important;
  }
  &.color-success {
    color: green !important;
  }
  &.color-warning {
    color: orange !important;
  }
}

.detail-view-image {
  max-width: 100%;
  max-height: 300px;
}

.overview-image {
  max-width: 100px;
  max-height: 100px;
}

.p-grid-border-bottom {
  border-bottom: 1px solid #EFEFEF;

  @media (max-width: 575px) {
    padding-bottom: 10px;
  }

  * {
    border-bottom: none !important;
  }
}

@media (min-width: 576px) {
  .float-right-sm {
    float: right;
  }
}

// <span class="color-swatch-left" [style.background]="row.color">
.color-swatch-left {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: -4px 8px -4px 0;
  border: 1px solid #111 !important;
}

.list-data ul {
  list-style-position: inside;
  margin: 0;
  li {
    clear: both;
    padding: 3px 0px;
  }
}

.p-selectbutton .p-button {
  border: none !important;
  background: #fff;
  margin: 0 4px !important;
  border-radius: 6px !important;
  &.p-highlight {
    border: none !important;
    background: #fff;
    border-radius: 50%;
    &:hover {
      background: #fff;
    }
    > span {
      border-radius: 50%;
      border: 1px solid #111 !important;
    }
  }
  > span {
    transition: border-radius 0.2s;
    padding: 0.85em 1.42em;
    margin: -0.85em -1.42em;
    border-radius: 0;
    border: 1px solid $gray500;
  }
}

.circle-image {
  border-radius: 50%;
  border: 1px solid #111 !important;
}

.margin-right {
  margin-right: 30px;
}

.disabled-opacity {
  opacity: 0.3 !important;
}

// primeNg Chip
.p-chip {
  background-color: #dee2e6;
  color: #495057;
  border-radius: 16px;
  padding: 0 0.5rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
  display: flex;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.filter-chips {
  &--wrapper {
    flex-wrap: wrap;

    p-chip {
      margin-bottom: 5px;

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }
}
:host ::ng-deep .p-chip {
  background: #bababa;
  //color: var(--primary-color-text);
}

.max-100 { width: 100px; }

.hide { display: none !important; }
