.avatar {
  position: relative;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: default;

  /*overflow: hidden;*/

  color: #ffffff;
  text-align: center;
  border-radius: 100%;
  background-size: cover;
  text-transform: uppercase;

  &.small {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  &.medium {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  &.large {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  &.xlarge {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
  }

  &.xxlarge {
    width: 100px;
    height: 100px;
    font-size: 20px;
    line-height: 100px;
  }
}

.datagrid {
  .avatar-wrapper {
    font-size: 10px;
    margin-top: -4px;
    margin-bottom: -5px;
    margin-right: 11px;
  }
}

.avatar-action {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 32px;
  height: 32px;
  background: #1077ab;
  border-radius: 50%;
  overflow: hidden;

  i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
  }
}
