/**
 * Emoji picker
 *
 * override ngx-emoji-mart/picker
 */

.emoji-picker-container {
  .emoji-mart {
    .emoji-mart-scroll {
      overflow-x: hidden;
      padding: 0 6px;
    }
  }
}

