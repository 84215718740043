.iv-l-sidebar {
  z-index: 998;

  .bottom-area.collapse-button {
    transition: left 0.3s ease;

    button {
      background-color: #f6f6f6;
      width: 30px;
      height: 30px;
      transition: all ease 0.3s;

      &:hover {
        background-color: #f6f6f6;
        box-shadow: 3px 0 6px 0 rgba(64, 64, 64, 0.25);
      }
    }
  }

  .iv-l-vnavigation .menu-item {
    .nav-link {
      color: #6a6a6a !important;
      transition: all ease 0.3s;

      &:hover {
        background-color: transparent !important;
        color: $black !important;
        border-left: 4px solid $black !important;
      }
    }

    &.active {
      .nav-link {
        background-color: transparent !important;
        color: $black !important;
        border-left: 4px solid $black !important;
      }
    }
  }
}

// Logo
.iv-l-header {
  height: 80px;

  .iv-l-header-inner {
    height: 80px;

    .iv-l-header-logo {
      height: 80px;
    }
  }
}

.iv-l-header-extension {
  margin: -80px 0 0 0;
}

#iv-l-wrapper {
  min-height: calc(100vh - 80px);
}

@media (min-width: 768px) {
  .iv-l-sidebar {
    height: calc(100vh - 80px);
  }

  .iv-l-header-fixed .iv-l-header {
    margin-top: -80px;
  }

  .iv-l-header-fixed + #iv-l-wrapper:not(.login-container) {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .iv-l-header-wrapper .iv-l-header .iv-l-header-inner .iv-l-header-logo {
    height: 80px;
    width: 150px;
    margin-left: 15px;
    background: transparent;
  }
}

.p-sidebar {
  padding: 0;

  .sidebar-container {
    padding: 40px;
  }

  .p-grid {
    margin: 0;
  }
}
