$dropzone-single-file-thumb-size: 120px;

form.submitted input.ng-invalid,
form.submitted .form-field-wrapper app-amount-input.ng-invalid input,
form.submitted .form-field-wrapper .ng-invalid input.ng-invalid,
.form-field-wrapper .ng-touched.ng-invalid input.ng-invalid,
input.ng-touched.ng-invalid {
  border: 1px solid #ab0000;
}

.actions .input-group,
.header .input-group {
  width: 220px;
  margin-left: 20px;
  position: relative;
}

.input-group.search-combo {
  width: 220px;
}

.btn-datatable {
  background: transparent !important;
  border-color: transparent !important;
  /*margin-top: -3px !important;*/
  margin-left: 4px !important;
  color: $primaryColor;
  padding: 0;
  margin-bottom: 0 !important;
}

.btn:active,
.btn.active {
  box-shadow: none !important;
}

.btn {
  border-radius: 6px;
  border: 2px solid $primaryColor;
}

.btn-primary,
.btn-primary:focus {
  background: $primaryColor;
}

.btn.btn-white {
  background: #ffffff;
}

.btn-text {
  display: inline-block;
  font-weight: bold;
}

.btn-icon .btn-text {
  margin-right: 5px;
}

.btn-no-margin {
  margin-left: 0 !important;
}

.btn-no-padding {
  padding: 0 !important;
}

.btn-no-background {
  background: none;
}

.input-group-btn:last-child > .btn {
  background-color: #eeeeee;
  border-color: #eeeeee;
  opacity: 1 !important;
  color: #666666;
}

.btn-no-border {
  border-width: 0px !important;
}

button[disabled] {
  pointer-events: none;
}

.inline-checkbox {
  margin: 10px 0px !important;

  .text {
    font-size: 13px;
    padding-top: 4px;
    display: inline-block;
  }
}

.control-checkbox {
  margin-bottom: 0 !important;
}

.alert {
  padding: 5px;
}

.alert h4 {
  margin-top: 0 !important;
}

.form-control {
  padding: 0.85em 1.42em !important;
  //font-size: $fontSize !important;
  border-radius: 6px;
  background-color: #efefef !important;
  width: 100%;
  color: $black;
  &:focus {
    outline: none;
  }
}

.p-dropdown-label {
  color: $black;
}

.form-text {
  font-size: $fontSize;
  display: inline-block;
  padding: 0.85em 1.42em;
}

.form-warning {
  line-height: 34px;
  text-align: center;
}

.mat-form-field {
  border: 2px solid #eeeeee !important;
  border-radius: 6px;
}

.input-group {
  .mat-form-field {
    //border-top-right-radius: 0 !important;
    //border-bottom-right-radius: 0 !important;

    .mat-form-field-wrapper .mat-form-field-flex {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

/*form.submitted .ng-invalid .form-control,*/
form.submitted .form-control.ng-invalid,
form.submitted .mat-form-field.ng-invalid,
form.submitted .dfg-field.ng-invalid input,
form.submitted p-autocomplete.ng-invalid input,
.custom-field.ng-invalid.ng-dirty .form-control,
.form-control.ng-invalid.ng-touched,
.dfg-field.ng-invalid.ng-touched .form-control,
.dfg-field.ng-invalid.ng-touched .p-inputtext,
.mat-form-field.ng-invalid.ng-touched,
form .dfg-field.ng-invalid.ng-touched .mat-form-field {
  border: 1px solid #ab0000 !important;
}

form .dfg-field.dfg-field-repeater {
  .form-control,
  .p-inputtext {
    width: 85%;
  }

  .amount-input {
    width: 85%;
    float: left;

    .input-group,
    .form-control,
    .p-inputtext {
      width: 100%;
    }
  }
}

typeahead-container.dropdown.open {
  width: 100%;

  ul {
    min-width: calc(100% - 31px) !important;
  }
}

.label-tooltip {
  padding-right: 10px;
}

.choice-row {
  overflow: hidden;
  margin-bottom: 10px;
}

.choice-title {
  float: left;
  width: 60% !important;
  margin-right: 5% !important;
}

.choice-weight {
  float: left;
  width: 20% !important;
  margin-right: 5% !important;
}

.source-desc {
  float: left;
  width: 35% !important;
  margin-right: 2% !important;
}

.source-other {
  float: left;
  width: 15% !important;
  margin-right: 2% !important;
}

.source-status {
  float: left;
  width: 19% !important;
  margin-right: 2% !important;
}

.source-select {
  padding: 6px 0px 6px 8px !important;
}

.choice-delete {
  float: left;
  width: 8% !important;

  .btn {
    margin-left: 0 !important;
    padding: 7px 11px !important;
  }
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control-indicator {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $primaryColor;
  background: #fff;
}

.control-radio .control-indicator {
  border-radius: 50%;
}

.control:hover input ~ .control-indicator,
.control input:focus ~ .control-indicator {
  background: #ccc;
}

.control input:checked ~ .control-indicator {
  background: $primaryColor;
}

.control:hover input:not([disabled]):checked ~ .control-indicator,
.control input:checked:focus ~ .control-indicator {
  background: $primaryColor;
}

.control input:disabled ~ .control-indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control-indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control-indicator:after {
  display: block;
}

.control-checkbox .control-indicator:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control-indicator:after {
  border-color: #7b7b7b;
}

.control-radio .control-indicator:after {
  left: 6px;
  top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.control-radio input:disabled ~ .control-indicator:after {
  background: #7b7b7b;
}

.mat-form-field-label-wrapper,
.mat-form-field-type-mat-select {
  .mat-form-field-label {
    margin-left: 1.42em;
    padding-right: 4em;
    color: $inputPlaceholderTextColor;
    font-style: $inputPlaceholderFontStyle;
    font-weight: normal;
  }
}

.mat-form-field {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-flex {
      border: 1px solid #eee;
      box-shadow: none;
      background: #eee;
      border-radius: 0px;
      height: 40px;

      .mat-form-field-infix {
        padding-left: 8px;
        padding-right: 8px;
        border-top: 0;
        line-height: 1.42857143;
      }
    }
  }
}

.input-group-addon {
  border: 2px solid #eeeeee;
  border-radius: 6px;
}

.btn-with-loader {
  position: relative;
}

.btn-loader {
  position: absolute;
  margin-left: -21px;
  top: 7px;
  left: 50%;
}

.btn-loader > div {
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
  background: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.btn-loader .ball-1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.btn-loader .ball-2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

textarea.form-control {
  height: 48px;
}

.fixed-text {
  padding-top: 6px;
}

.mat-expansion-panel.ellipsis-title {
  .mat-expansion-panel-header {
    min-width: 0;
    flex-basis: 50%;

    .mat-content {
      min-width: 0;
      flex-basis: 50%;

      .mat-expansion-panel-header-title {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.dynamic-form-width {
  width: calc(100% - 50px);
}

.full-width-important {
  width: 100% !important;
}

app-multi-language-input.full-width-important {
  .full-width,
  input,
  textarea {
    width: 100% !important;
  }
}

.dynamic-form {
  @include media(extraSmall) {
    .p-grid.p-grid-border-bottom {
      margin-bottom: 10px;
      .p-col-12 {
        border-bottom: none;
      }
    }
  }

  .p-progress-spinner {
    width: 30px;
    height: 30px;
  }

  .mdi.valid {
    color: green !important;
  }

  .info-tooltip {
    color: $primaryColor;
    margin-right: 5px;
  }

  .form-group.p-grid.ng-star-inserted {
    padding: 2px 0;
  }

  .control-label {
    font-weight: bold;
    text-align: left;
  }

  .p-dropdown {
    min-width: 0;
  }

  .form-control:not(.source-form-control),
  .p-inputgroup:not(.source-form-control),
  .checkbox-container:not(.source-form-control),
  .p-calendar:not(.source-form-control),
  .p-inputtext:not(.source-form-control),
  .p-multiselect:not(.source-form-control),
  .radioContainer:not(.source-form-control),
  .p-dropdown:not(.source-form-control),
  .dfg-field.file-upload {
    float: left;
    width: calc(100% - 50px);
  }

  app-multi-language-input .full-width {
    float: left;
    width: calc(100% - 50px);
    .p-inputtext {
      width: 100%;
    }
  }

  .p-inputgroup .p-inputtext:not(.source-form-control),
  .p-calendar:not(.source-form-control) .p-inputtext {
    width: calc(100% - 40px);
  }

  .repeater-wrapper {
    .form-control:not(.source-form-control),
    .p-inputgroup:not(.source-form-control),
    .p-calendar:not(.source-form-control),
    .p-inputtext:not(.source-form-control),
    .p-multiselect:not(.source-form-control),
    .p-dropdown:not(.source-form-control) {
      width: 100%;
    }
  }

  .form-max-char-count {
    float: left;
    width: calc(100% - 50px);
    margin-top: 5px;
    font-size: 11px;

    .count {
      font-weight: bold;
    }
  }

  .repeater-row {
    margin-top: 0px;

    .choice-row {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }

    .source-row {
      margin-bottom: 10px;
    }

    .choice-delete {
      margin-left: 10px;
    }
  }

  .funding-border,
  .funding-row {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .repeater-wrapper,
  .dz-wrapper > .dropzone,
  .mat-form-field {
    float: left;
    width: calc(100% - 50px) !important;
  }

  .input-group {
    margin-bottom: 0;
    float: left;
    width: calc(100% - 50px);

    .form-control {
      width: 100%;
    }
  }

  .source-form-control {
    .input-group {
      width: 100%;
    }
  }

  .form-field-extras {
    margin-left: 10px;
    float: right;
    width: 40px;
  }

  .form-warning {
    display: inline-block;
    text-align: left;
    color: #ab0000;
  }

  .form-response {
    padding-top: 7px;
  }
}

.tooltip {
  position: absolute !important;
}

.fieldError {
  .tooltip-inner {
    font-family: $fontFamily;
    padding: 6px 12px;
  }
}

.form-row-height {
  height: 51.4px;
}

.input-padding-top {
  padding-top: 4px;
}

@include media(mediumUp) {
  .wizard-btn-marg {
    margin-right: 7.8%;
  }
}

.mat-expansion-panel-content {
  font: inherit;
}

.search-select-field {
  min-width: 300px;

  input.form-control {
    width: 50%;
    float: left;

    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 44px;
  }

  .search-filter-select-wrapper {
    position: relative;
    float: left;
    display: block;
    height: 44px;
    width: calc(50% - 44px);

    .search-filter-select {
      height: 44px;
      width: 100%;
      border-radius: 0;
      border: none;
      background: #eee;
    }

    .p-dropdown .p-dropdown-trigger {
      right: 20px;

      .p-dropdown-trigger-icon {
        margin-top: 3px !important;
      }
    }

    & + .p-button.p-button-transparent {
      position: relative;

      i {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 3px;
      }
    }
  }

  .mat-select {
    width: calc(50% - 44px);
    height: 44px;
    float: left;
    background: #eee; // variable
    display: block;

    .mat-select-trigger {
      height: 44px;
    }

    .mat-select-value {
      vertical-align: middle;
    }
  }
  .form-control:focus {
    border-color: transparent;
  }

  .p-button.p-button-transparent {
    background-color: #efefef;
    color: #ccc;
    height: 44px;
    width: 44px;

    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .p-button-label {
      display: none;
    }

    &:hover,
    &:focus {
      color: #404040;
      background-color: #efefef;
    }
  }
}

.filters {
  @include media(extraSmall) {
    .search-select-field input.form-control {
      width: 100%;
      -webkit-border-top-left-radius: 6px;
      -webkit-border-top-right-radius: 6px;
      -moz-border-radius-topleft: 6px;
      -moz-border-radius-topright: 6px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-bottom-left-radius: 0px;
    }
    .search-select-field {
      .p-button.p-button-transparent {
        -webkit-border-top-right-radius: 0px;
        -moz-border-radius-topright: 0px;
        border-top-right-radius: 0px;
      }
      .search-filter-select-wrapper {
        width: calc(100% - 44px);

        .search-filter-select {
          -webkit-border-bottom-left-radius: 6px;
          -moz-border-radius-bottomleft: 6px;
          border-bottom-left-radius: 6px;
        }
      }
    }
  }
  @include media(small) {
    .p-col-fixed,
    .p-col {
      //width: 100%;
    }
    .iv-l-input-md {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .search-select-field {
      min-width: auto;
    }
  }
}

/* PrimeNG Multiselect Group */
.multiSelectGroup {
  .p-multiselect-item.p-state-disabled {
    font-weight: bold !important;
    opacity: 1 !important;

    .p-chkbox {
      display: none !important;
    }
  }
}

/**
  PrimeNG clearable dropdown fixes
 */
.p-dropdown-clearable {
  .p-dropdown-label {
    padding-right: 0;
    width: calc(100% - 4em);
  }
  .p-dropdown-trigger {
    .p-dropdown-trigger-icon {
      &:before {
        line-height: 35px !important;
      }
    }
  }
  .p-dropdown-clear-icon {
    &:before {
      line-height: 15px !important;
    }
  }
}

/**
  PrimeNG buttons
 */
.p-button.p-button-icon-centered [class^='p-button-icon']::before {
  line-height: 18px;
}

/**
  Dropzone
 */
body dropzone > .dropzone {
  &.dz-wrapper {
    z-index: 1;

    .dz-preview {
      .dz-details {
        color: $gray500;
        z-index: 1001;

        .dz-filename span {
          border: none;
        }
      }

      .dz-progress {
        animation: none;
        border-color: $gray200;
        border-radius: 6px;

        .dz-upload {
          background: $primaryColor;
        }
      }
    }

    &.dz-multiple {
      .dz-progress {
        top: 50px;
      }
    }

    &.dz-single.dz-started {
      .dz-preview {
        .dz-remove {
          position: absolute;
          left: 0;
          right: 0;
          z-index: 1002;
        }

        &.dz-image-preview {
          height: $dropzone-single-file-thumb-size;

          .dz-image {
            height: $dropzone-single-file-thumb-size;
            width: $dropzone-single-file-thumb-size;

            img {
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              margin: 0 auto;
              min-width: $dropzone-single-file-thumb-size;
              width: auto;
              transform: translateY(-50%);
            }
          }

          &:hover .dz-image img {
            filter: none;
            transform: translateY(-50%);
          }

          .dz-progress {
            width: calc(80% - #{$dropzone-single-file-thumb-size});
            left: $dropzone-single-file-thumb-size;
            right: 0;
            margin: -16px auto 0;
          }

          .dz-details {
            padding-right: 0;
            padding-left: $dropzone-single-file-thumb-size;
            opacity: 1;

            .dz-filename span {
              background-color: transparent;
            }
          }

          .dz-remove {
            padding-left: $dropzone-single-file-thumb-size;
            bottom: 18px;
          }

          .dz-error-message {
            padding-left: $dropzone-single-file-thumb-size;
          }
        }
      }

      .dz-message {
        margin: 8px 0;
        max-width: 100%;
      }
    }
  }
}

.half-width {
  width: 50% !important;

  &.right-margin {
    margin-right: 30px;
    width: calc(50% - 30px) !important;
  }
}

.btn-disabled {
  background-color: $gray200;
}

@media (max-width: 578px) {
  .p-inputtext, .p-calendar, .p-dropdown, input[type="file"], .dynamic-form-width {
    width: 100% !important;
  }
}

// styles for appMaxLengthDirective
.counter-error {
  color: red;
}

.dynamic-form .p-grid .p-grid .p-grid .p-inputtext:not(.source-form-control),
.dynamic-form .p-grid .p-grid .p-grid .p-dropdown:not(.source-form-control){
  float: right !important;
  margin-right: 20px;
  @media (max-width: 578px) {
    float: left !important;
    margin-right:0 ;
  }
}

.p-dropdown-items-wrapper {
  width: 100%
}

.p-dropdown-panel .p-dropdown-header { border-bottom: 0; }

.p-button-transparent {
  .p-splitbutton-defaultbutton,
  .p-splitbutton-menubutton {
    border: 1px solid #bababa;
    background: none;
    color: #bababa;

    &:hover {
      background: none;
      color: #404040;
      border: 1px solid #bababa;
    }
  }

  .p-splitbutton-defaultbutton {
    cursor: default;

    &:hover {
      color: #bababa;
    }

    .p-button-icon {
      position: relative;
      top: 2px;
    }
  }
}
