//  Whitelabel overrule
@import 'custom.scss';

// QUILL EDITOR
//@import '../node_modules/quill/dist/quill.core.css';
//@import '../node_modules/quill/dist/quill.snow.css';

// NGX-EMOJI-PICKER
//@import '../node_modules/@ctrl/ngx-emoji-mart/picker';

// regular style toast
@import '../node_modules/ngx-toastr/toastr.css';

// MATERIAL DESIGN ICONS
$icon-font: '~@mdi/font/fonts';
$mdi-font-path: '~@mdi/font/fonts';
@import '../node_modules/@mdi/font/scss/materialdesignicons.scss';

// @NGX-IVENGI/LAYOUT
$img-path: '/assets/images';
$headerProfileMenuMarginTop: 1em;
$headerNotificationMenuMarginTop: 1em;

@import '../node_modules/@ngx-ivengi/layout/assets/scss/style.scss';

.fc-event-container {
  cursor: pointer !important;
}

//@import '../node_modules/swiper/swiper-bundle.css';
//@import 'swiper.scss';

/////////////////////////////
@import 'buttons.scss';
@import 'global.scss';
@import 'datatable.scss';
@import 'datepicker.scss';
@import 'form.scss';
@import 'helpers.scss';
@import 'tooltip.scss';
@import 'fullcalendar.scss';
@import 'entity-avatar.scss';
@import 'iv-layout-custom.scss';
@import 'progress-bar.scss';
@import 'table.scss';
@import 'treetable.scss';
@import 'tabs.scss';
@import 'typography.scss';
@import 'sidebar.scss';
@import 'emoji-picker.scss';
