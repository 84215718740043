table {
  th,
  td {
    vertical-align: top;
    outline: none;
  }
  .tr-border {
    td {
      border-bottom: 1px solid $gray100;
    }

    &:first-of-type {
      td {
        border-top: 1px solid $gray100;
      }
    }
  }
}

// force breakpoint for p-table with class .responsive-breakpoint-[breakpoint]
// because primeNG p-table cannot force breakpoint for responsive table

@each $screen-size in $screen-sizes {
  @media screen and (max-width: map_get($screen-size, value)) {
    body .p-datatable.p-datatable-responsive.responsive-breakpoint-#{map_get($screen-size, name)} {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
        box-sizing: border-box;
        float: left;
        clear: left;
        padding: 0.8em 1.25em;

        .p-column-title {
          color: $gray750;
          padding: 0.4em;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4em;
          font-weight: bold;
        }

        &:last-child {
          padding-bottom: 1.6em;
          margin-bottom: 0.8em;
          border-bottom: $tableBodyCelBorderBottom;
        }

        &.no-padding-left {
          padding-left: 1.25em !important;
        }
        &.no-padding-right {
          padding-right: 1.25em !important;
        }
      }
    }
  }
}

body .p-datatable .p-datatable-thead > tr > th {
  white-space: nowrap;

  .ui-column-filter {
    max-width: 200px;
  }
}
body .p-datatable {
  .p-datatable-thead,
  .p-datatable-tbody,
  .p-datatable-tfoot {
    tr td {
      padding: 1.25em;
      vertical-align: middle;
      color: $black;
      justify-content: start !important;

      a {
        color: $primaryColor;
      }

      button {
        padding: 0;

        &.allow-padding {
          padding: 0.85em 1.42em
        }
      }
    }
  }
  .colName_sm {
    width: 40%;
    display: inline-block;

    @media (min-width: 1200px) {
      display: none;
    }
  }
  &.p-datatable-responsive.responsive-breakpoint-xl .p-datatable-thead {
    @media (max-width: 1200px) {
      tr:nth-child(2) {
        display: none;
      }
      tr > th {
        display: block !important;
        margin: 0 0 10px 0;
        padding: 0 !important;
      }
    }
  }
  .p-sortable-column .p-sortable-column-badge {
    display: none;
  }
}

.table-row-image {
  width: 50px;
  height: 50px;
  margin: -7px 0;
}

@media (max-width: 960px) {
  .p-datatable-table {
    margin-top: 10px;
    border-top: 1px solid #EFEFEF;
  }
}
