.p-button {
  border-radius: 7px;
}

.reset {
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 10px;

  .p-button-label {
    font-weight: 400;
    font-family: 'Lato';
  }

  .p-button-icon {
    display: flex;
    align-items: center;
  }

  @media (max-width: 576px) {
    margin-top: 0;
  }
}

.trash-can-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: $gray500;

  span.mdi-trash-can {
    display: flex;
  }

}
