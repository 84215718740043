$directions: ('top', 'right', 'bottom', 'left');

.resize-vertical-only {
  resize: vertical;
}

.no-margin-top {
  margin-top: 0 !important;
}

.pointer-forbidden {
  cursor: not-allowed !important;
}

.cursor-move {
  cursor: move;
}

.line-through {
  text-decoration: line-through;
}

.cursor-default {
  cursor: default;
}

.one-line-ellipsis {
  margin-left: 0px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fade-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.color-black {
  color: $black;
}

.position-relative {
  position: relative;
}

@each $direction in $directions {
  .spacer-#{$direction}-xxs {
    margin-#{$direction}: 5px;
  }

  .no-border-#{$direction} {
    border-#{$direction}: none !important;
  }
}

.font-weight-bold {
  font-weight: $headingFontWeight;
}
