.p-datepicker {
  button {
    background-color: $black !important;
    color: $white !important;
    padding: 10px;

    .p-datepicker-next-icon, .p-datepicker-prev-icon {
      display: flex;
    }
  }
}
.pi.pi-chevron-left:before {
  content: '<';
  font-family: ff-tisa-sans-web-pro,sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.pi.pi-chevron-right:before {
  content: '>';
  font-family: ff-tisa-sans-web-pro,sans-serif;
  font-size: 16px;
  font-weight: bold;
}
