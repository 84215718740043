$progress-bar-primary-color: #afd798;
$progress-bar-secondary-color: #dddddd;
$progress-bar-warning-color: #ffbb7f;
$progress-bar-error-color: #d57f7f;
$progress-bar-stripe-color: #ffffff;

.custom-progress-bar {
  display: flex;
  margin: 0 0 15px 0;
  height: 12px;
  border-radius: 10px;
  overflow: hidden;

  .inner-bar {
    display: inline-block;
    height: 100%;

    &.primary-bar {
      background-color: $progress-bar-primary-color;

      &.striped {
        background-color: transparent;
        background-image: linear-gradient(
          135deg,
          $progress-bar-stripe-color 25%,
          $progress-bar-primary-color 25%,
          $progress-bar-primary-color 50%,
          $progress-bar-stripe-color 50%,
          $progress-bar-stripe-color 75%,
          $progress-bar-primary-color 75%,
          $progress-bar-primary-color 100%
        );
        background-size: 5px 5px;
      }
    }

    &.secondary-bar {
      background-color: $progress-bar-secondary-color;

      &.striped {
        background-color: transparent;
        background-image: linear-gradient(
          135deg,
          $progress-bar-stripe-color 25%,
          $progress-bar-secondary-color 25%,
          $progress-bar-secondary-color 50%,
          $progress-bar-stripe-color 50%,
          $progress-bar-stripe-color 75%,
          $progress-bar-secondary-color 75%,
          $progress-bar-secondary-color 100%
        );
        background-size: 5px 5px;
      }
    }

    &.warning-bar {
      background-color: $progress-bar-warning-color;

      &.striped {
        background-color: transparent;
        background-image: linear-gradient(
          135deg,
          $progress-bar-stripe-color 25%,
          $progress-bar-warning-color 25%,
          $progress-bar-warning-color 50%,
          $progress-bar-stripe-color 50%,
          $progress-bar-stripe-color 75%,
          $progress-bar-warning-color 75%,
          $progress-bar-warning-color 100%
        );
        background-size: 5px 5px;
      }
    }

    &.error-bar {
      background-color: $progress-bar-error-color;

      &.striped {
        background-color: transparent;
        background-image: linear-gradient(
          135deg,
          $progress-bar-stripe-color 25%,
          $progress-bar-error-color 25%,
          $progress-bar-error-color 50%,
          $progress-bar-stripe-color 50%,
          $progress-bar-stripe-color 75%,
          $progress-bar-error-color 75%,
          $progress-bar-error-color 100%
        );
        background-size: 5px 5px;
      }
    }
  }
}

.p-progressbar .p-progressbar-value {
  background: $black !important;
}
