body p-treetable .p-treetable-thead tr td,
body p-treetable .p-treetable-tbody tr td,
body .p-treetable .p-treetable-tfoot tr td {
  padding: 1.25em;
  vertical-align: middle;
  color: #000000;
  justify-content: start !important;
}

body .p-treetable .p-treetable-tbody tr.subRow {
  background: #f9f9f9;
}

body .p-treetable .p-treetable-tbody tr td {
  border-bottom: 1px solid #EFEFEF;
}
