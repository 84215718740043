.iv-l-sidebar .iv-l-vnavigation .menu-item.active > .nav-link {
  color: $white !important;
  background-color: $gray500 !important;
}

.iv-l-sidebar {
  z-index: 2;
}

.iv-l-sidebar .iv-l-vnavigation .menu-item .p-accordion-header .nav-link:hover {
  background-color: transparent !important;
}

.iv-l-header .iv-l-header-inner .iv-l-header-content .iv-l-header-not-nav .iv-l-header-profile .profile-name {
  max-width: 500px;
}

.p-grid.filter > div {
  margin: 3px 0;
  .p-inputtext {
    padding: 0.85em;
  }
  .p-multiselect {
    .p-multiselect-label {
      padding: 0.85em;
    }
    .p-multiselect-trigger {
      width: 1.5rem;
    }
  }
  .p-dropdown {
    .p-dropdown-clear-icon {
      right: 1.5rem;
    }
    .p-dropdown-trigger {
      width: 1.5rem;
    }
  }
}

.p-grid.filter + .p-grid {
  margin-top: 10px;
}

.p-card-content {
  a {
    color: $primaryColor;
  }
}

.emoji-picker-toggle {
  display: block;
  position: relative;
  float: left;
  padding: 15px 0;
  width: 24px;
  font-size: 24px;
  clear: both;
}

.emoji-picker-container {
  display: block;
  position: relative;
  float: left;
  width: 24px;
  height: 24px;
}
